<template>
  <div>
      <!-- <van-tabbar v-model="$route.meta.activeNum"> -->
      <van-tabbar v-model="active">
          <van-tabbar-item to="/index" icon="home-o">首页</van-tabbar-item>
          <van-tabbar-item to="/course" icon="label-o">选课</van-tabbar-item>
          <van-tabbar-item to="/learning" icon="play-circle-o">学习</van-tabbar-item>
          <van-tabbar-item to="/Question" icon="edit">题库</van-tabbar-item>
          <van-tabbar-item to="/user" icon="user-o">我的</van-tabbar-item>
      </van-tabbar>
  </div>
</template>

<script>
export default {
  name: 'AppTabbar',
  data () {
    return {
      active:0,
    }
  },
  setup() {
    const active = ref(0);
    return { active };
  },
  computed: {
    active() {
      return this.$route.meta.activeNum
    }
  },
  mounted(){
      // 解决刷新后，tabbar高亮按钮始终显示在第一个上
      // window.location.pathname获取地址栏中的路径
      // switch(this.$route.path){
      //   case '/index' :  this.active = 0;break;
      //   case '/course' :  this.active = 1;break;
      //   case '/learning' :  this.active = 2;break;
      //   case '/Question' :  this.active = 3;break;
      //   case '/user' :  this.active = 4;break;
      //   default : this.active = 0;
      // }
    }

}
</script>
 
<style lang = "less" scoped>

</style>