import {
    Toast
    // Toast
} from 'vant';
import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../views/Index.vue'
import {appLogin,getCateNewUser} from "@/request/api";
import fr from "element-ui/src/locale/lang/fr";

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function (location) {
    return originalPush.call(this, location).catch(err => {
    })
}

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Index',
        redirect: "/index"
    },
    {
        path: '/index/:branch?',
        name: 'Index',
        component: Index,
        meta: {
            activeNum: 0,
            isShowTabbar: true,
            selectCate: true
        },
        children: [
            {
                path: '/index/searchPopup',
                name: 'SearchPopup',
                component: () => import( /* webpackChunkName: "SearchPopup" */ '../views/SearchPopup.vue'),
                meta: {
                    activeNum: 0,
                    isShowTabbar: true,
                    selectCate: true
                },
            },
            {
                path: '/index/project',
                name: 'Project',
                component: () => import( /* webpackChunkName: "SearchPopup" */ '../views/Project.vue'),
                meta: {
                    activeNum: 0,
                    isShowTabbar: true,
                    selectCate: false
                },
            }
        ]
    },
    {
        path: '/course/:branch?',
        name: 'Course',
        meta: {
            activeNum: 1,
            isShowTabbar: true,
            selectCate: true
        },
        component: () => import( /* webpackChunkName: "Course" */ '../views/Course.vue'),

        children: [
            {
                path: '/course/project',
                name: 'Project',
                component: () => import( /* webpackChunkName: "SearchPopup" */ '../views/Project.vue'),
                meta: {
                    activeNum: 0,
                    isShowTabbar: true,
                    selectCate: true
                },
            }
        ]

    },
    {
        path: '/courseApp',
        name: 'CourseApp',
        meta: {
            selectCate: false
        },
        component: () => import( /* webpackChunkName: "Course" */ '../views/CourseApp.vue'),

    },
    {
        path: '/learning:branch?',
        name: 'Learning',
        meta: {
            activeNum: 2,
            isShowTabbar: true,
            requireauth: true,
            selectCate: true
        },
        component: () => import( /* webpackChunkName: "learning" */ '../views/Learning.vue')

    },
    {
        path: '/learningList',
        name: 'LearningList',
        meta: {
            requireauth: true
        },
        component: () => import( /* webpackChunkName: "learning" */ '../views/LearningList.vue')

    },
    {
        path: '/question',
        name: 'Question',
        meta: {
            activeNum: 3,
            isShowTabbar: true,
            selectCate: true
        },
        component: () => import( /* webpackChunkName: "Cart" */ '../views/Question.vue'),

        children: [
            {
                path: '/question/project',
                name: 'Project',
                component: () => import( /* webpackChunkName: "SearchPopup" */ '../views/Project.vue'),
                meta: {
                    activeNum: 3,
                    isShowTabbar: true,
                    selectCate: true
                },
            },
            {
                path: '/question/searchPopup',
                name: 'SearchPopup',
                component: () => import( /* webpackChunkName: "SearchPopup" */ '../views/SearchPopup.vue'),
                meta: {
                    activeNum: 3,
                    isShowTabbar: true,
                    selectCate: true
                },
            }
        ]

    },
    {
        path: '/user:branch?',
        name: 'User',
        meta: {
            activeNum: 4,
            isShowTabbar: true,
            selectCate: true
        },
        component: () => import( /* webpackChunkName: "User" */ '../views/User.vue'),
        

        // children: [
        //     {
        //         path: '/user/project',
        //         name: 'Project',
        //         component: () => import( '../views/Project.vue'),
        //         meta: {
        //             activeNum: 4,
        //             isShowTabbar: true,
        //             selectCate: true
        //         },
        //     },
        // ]

    },
    {
        path: '/productDetail',
        name: 'ProductDetail',
        meta: {
            selectCate: true
        },
        component: () => import( /* webpackChunkName: "ProductDetail" */ '../views/ProductDetail.vue')

    },
    {
        path: '/signIn',
        name: 'SignIn',
        meta: {
            selectCate: true
        },
        component: () => import( /* webpackChunkName: "learning" */ '../views/SignIn.vue')

    },
    {
        path: '/courseDetail',
        name: 'CourseDetail',
        meta: {
            requireauth: false,
            selectCate: false
        },
        component: () => import( /* webpackChunkName: "ProductDetail" */ '../views/CourseDetail.vue'),
        // children: [
        //   {
        //   path: '/courseDetail/commodityEvaluation',
        //   name: 'CommodityEvaluation',
        //   component: () => import( /* webpackChunkName: "CommodityEvaluation" */ '../views/CommodityEvaluation.vue')
        //   }
        // ]
    },
    {
        path: '/cart',
        name: 'Cart',
        meta: {
            selectCate: true
        },
        component: () => import( /* webpackChunkName: "ProductDetail" */ '../views/Cart.vue')

    },
    {
        path: '/orderList',
        name: 'OrderList',
        meta: {
            requireauth: true, // 添加该字段，表示进入这个路由是需要登录的
            selectCate: true
        },
        component: () => import( /* webpackChunkName: "ProductDetail" */ '../views/OrderList.vue')

    },
    {
        path: '/order',
        name: 'Order',
        meta: {
            selectCate: true
        },
        component: () => import( /* webpackChunkName: "ProductDetail" */ '../views/Order.vue')

    },
    {
        path: '/addressManagement',
        name: 'AddressManagement',
        meta: {
            selectCate: true
        },
        component: () => import( /* webpackChunkName: "ProductDetail" */ '../views/AddressManagement.vue')

    },
    {
        path: '/addressEdit',
        name: 'AddressEdit',
        meta: {
            selectCate: true
        },
        component: () => import( /* webpackChunkName: "ProductDetail" */ '../views/AddressEdit.vue')

    },
    {
        path: '/commodityEvaluation',
        name: 'CommodityEvaluation',
        meta: {
            selectCate: true
        },
        component: () => import( /* webpackChunkName: "CommodityEvaluation" */ '../views/CommodityEvaluation.vue')
    },
    {
        path: '/questionList',
        name: 'QuestionList',
        meta: {
            selectCate: true
        },
        component: () => import( /* webpackChunkName: "QuestionList" */ '../views/QuestionList.vue')
    },
    {
        path: '/questionListK', //题库列表 知识点
        name: 'QuestionListK',
        meta: {
            selectCate: true
        },
        component: () => import( /* webpackChunkName: "QuestionList" */ '../views/QuestionListK.vue')
    },
    {
        path: '/questionDailyPracticeList', //题库列表 知识点
        name: 'QuestionDailyPracticeList',
        meta: {
            selectCate: true
        },
        component: () => import( /* webpackChunkName: "QuestionList" */ '../views/QuestionDailyPracticeList.vue')
    },
    {
        path: '/paperDetail',
        name: 'PaperDetail',
        meta: {
            selectCate: true
        },
        component: () => import( /* webpackChunkName: "QuestionList" */ '../views/PaperDetail.vue')
    },
    {
        path: '/paperPractice',
        name: 'PaperPractice',
        meta: {
            selectCate: true
        },
        component: () => import( /* webpackChunkName: "QuestionList" */ '../views/PaperPractice.vue')
    },
    {
        path: '/paperExam',
        name: 'PaperExam',
        meta: {
            selectCate: true
        },
        component: () => import( /* webpackChunkName: "QuestionList" */ '../views/PaperExam.vue')
    },
    {
        path: '/zxks',
        name: 'Zxks',
        meta: {
            selectCate: true
        },
        component: () => import( /* webpackChunkName: "QuestionList" */ '../views/zxks.vue')
    },
    {
        path: '/zxks_dtlx', // 练习模式
        name: 'zxksDTLX',
        meta: {
            selectCate: true
        },
        component: () => import( /* webpackChunkName: "QuestionList" */ '../views/zxks_dtlx.vue')
    },
    {
        path: '/zxks_dt',
        name: 'zxksDT',
        meta: {
            selectCate: true
        },
        component: () => import( /* webpackChunkName: "QuestionList" */ '../views/zxks_dt.vue')
    },
    {
        path: '/zxksks',
        name: 'zxksks',
        meta: {
            selectCate: true
        },
        component: () => import( /* webpackChunkName: "QuestionList" */ '../views/zxksks.vue')
    },
    {
        path: '/calculator',
        name: 'calculator',
        meta: {
            selectCate: true
        },
        component: () => import( /* webpackChunkName: "QuestionList" */ '../views/calculator.vue')
    },
    {
        path: '/QuestionRecord',//做题记录
        name: 'questionRecord',
        // meta: {
        requireauth: true, // 添加该字段，表示进入这个路由是需要登录的
        //     selectCate: true
        // },
        component: () => import( /* webpackChunkName: "QuestionList" */ '../views/QuestionRecord.vue')
    },
    {
        path: '/QuestionErrorSet',//错题集
        name: 'questionErrorSet',
        // meta: {
        requireauth: true, // 添加该字段，表示进入这个路由是需要登录的
        //     selectCate: true
        // },
        component: () => import( /* webpackChunkName: "QuestionList" */ '../views/QuestionErrorSet.vue')
    },
    {
        path: '/QuestionCollection',//题目收藏
        name: 'questionCollection',
        // meta: {
        //     requireauth: true, // 添加该字段，表示进入这个路由是需要登录的
        //     selectCate: true
        // },
        component: () => import( /* webpackChunkName: "QuestionList" */ '../views/QuestionCollection.vue')
    },
    {
        path: '/QuestionDayEcharts',//学习周报
        name: 'questionDayEcharts',
        // meta: {
        //     requireauth: true, // 添加该字段，表示进入这个路由是需要登录的
        //     selectCate: true
        // },
        component: () => import( /* webpackChunkName: "QuestionList" */ '../views/QuestionDayEcharts.vue')
    },
    {
        path: '/QuestionAnswer',//学习周报
        name: 'questionAnswer',
        // meta: {
        requireauth: true, // 添加该字段，表示进入这个路由是需要登录的
        //     selectCate: true
        // },
        component: () => import( /* webpackChunkName: "QuestionList" */ '../views/QuestionAnswer.vue')
    },
    {
        path: '/QuestionAnswerDetail',//学习周报
        name: 'questionAnswerDetail',
        // meta: {
        requireauth: true, // 添加该字段，表示进入这个路由是需要登录的
        //     selectCate: true
        // },
        component: () => import( /* webpackChunkName: "QuestionList" */ '../views/QuestionAnswerDetail.vue')
    },
    {
        path: '/freeAudition',
        name: 'FreeAudition',
        meta: {
            selectCate: false
        },
        component: () => import( /* webpackChunkName: "QuestionList" */ '../views/FreeAudition.vue')
    },
    {
        path: '/specialFreeAudition',
        name: 'SpecialFreeAudition',
        meta: {
            selectCate: false
        },
        component: () => import( /* webpackChunkName: "QuestionList" */ '../views/SpecialFreeAudition.vue')
    },
    {
        path: '/freeAudition11',
        name: 'FreeAudition11',
        meta: {
            selectCate: false
        },
        component: () => import( /* webpackChunkName: "QuestionList" */ '../views/FreeAudition11.vue')
    },
    {
        path: '/auditionDetail',
        name: 'AuditionDetail',
        meta: {
            // requireauth: true, // 添加该字段，表示进入这个路由是需要登录的
            selectCate: false
        },
        component: () => import( /* webpackChunkName: "QuestionList" */ '../views/AuditionDetail.vue')
    },
    {
        path: '/login',
        name: 'Login',
        meta: {
            selectCate: false
        },
        component: () => import( /* webpackChunkName: "User" */ '../views/Login.vue')

    },
    {
        path: '/MyCourseDetail',
        name: 'MyCourseDetail',
        meta: {
            requireauth: true, // 添加该字段，表示进入这个路由是需要登录的
            selectCate: true
        },
        component: () => import( /* webpackChunkName: "QuestionList" */ '../views/MyCourseDetail.vue')
    },
    {
        path: '/LearningRecord',
        name: 'LearningRecord',
        meta: {
            //requireauth: true, // 添加该字段，表示进入这个路由是需要登录的
            selectCate: true
        },
        component: () => import( /* webpackChunkName: "QuestionList" */ '../views/LearningRecord.vue')
    },
    {
        path: '/websiteInfo/:branch?/:type?',
        name: 'websiteInfo',
        meta: {
            //requireauth: true, // 添加该字段，表示进入这个路由是需要登录的
            selectCate: false
        },
        component: () => import( /* webpackChunkName: "QuestionList" */ '../views/websiteInfo.vue')
    },
    {
        path: '/MySchool',
        name: 'mySchool',
        meta: {
            requireauth: true, // 添加该字段，表示进入这个路由是需要登录的
            selectCate: true
        },
        component: () => import( /* webpackChunkName: "QuestionList" */ '../views/MySchool.vue')
    },
    {
        path: '/CourseData/',
        name: 'CourseData',
        meta: {
            requireauth: true, // 添加该字段，表示进入这个路由是需要登录的
            // selectCate: true
        },
        component: () => import( /* webpackChunkName: "QuestionList" */ '../views/CourseData.vue')
    },
    // {
    //   path: '/about',
    //   name: 'About',
    //   // route level code-splitting
    //   // this generates a separate chunk (about.[hash].js) for this route
    //   // which is lazy-loaded when the route is visited.
    //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    // }
]

const router = new VueRouter({
    // mode: 'history',
    // base: process.env.VUE_APP_BASE_API,
    routes
})


// 前置路由守卫
router.beforeEach((to, from, next) => {
    // 要去往的路由对象
    // console.log("to", to);
    // 从哪里来的路由
    // console.log("from", from);
    // next放行  可以顺利到达要去的路由
    //测试
    // localStorage.setItem('groupId','')
    // localStorage.setItem('subjectId','')
    // localStorage.removeItem('token');
    // localStorage.removeItem('userInfo');
    // 获取登录凭证
    // if (to.path == "/cart") { // 表示去往购物车页面
    //   // 判断有没有登录,判断token是否存在
    //   if (token) {
    //     next()
    //   } else {
    //     // 表示没有登录
    //     Vue.prototype.$toast("请先登录")
    //     setTimeout(() => {
    //       next("/user") // 跳转到user路由
    //     }, 1000);
    //   }
    //   // return
    // }
    // next()
    let token = localStorage.getItem("token");
    /**
     * app请求 token 标识
     * 如果请求 get参数token ，请求服务器用户其他参数
     * vue不弹出登录 进入下一步
     */
    if(!token && typeof(to.query.token) != "undefined") {
        console.log('正在app登录');
        //App请求
        appLogin({token:to.query.token})
            .then(res => {
                if(res.data.code == 1) {
                    localStorage.setItem("token", res.data.data.jwt);
                    localStorage.setItem("userInfo", JSON.stringify(res.data.data.userInfo));
                    localStorage.setItem("branchId", res.data.data.userInfo.branchId);
                    localStorage.setItem("adminId", res.data.data.userInfo.adminId);
                    localStorage.setItem("groupId", res.data.data.userInfo.groupId);
                    localStorage.setItem("subjectId", res.data.data.userInfo.subjectId);
                    localStorage.setItem("title", res.data.data.userInfo.title);
                    localStorage.setItem("appLogin", true);
                    localStorage.setItem("groupName",res.data.data.cate_name);
                    console.log('App登录');
                    verify();
                }
            })
            .catch(err => {
                console.log("error");
                console.log(err);
                Toast.fail('请求错误');
            })
    } else {
        if(typeof(to.query.token) != "undefined") {
            getCateNewUser({token:to.query.token})
                .then(res => {
                    if(res.data.code == 1) {
                        localStorage.setItem("groupId", res.data.data.userInfo.groupId);
                        localStorage.setItem("subjectId", res.data.data.userInfo.subjectId);
                        localStorage.setItem("groupName",res.data.data.cate_name);
                        console.log('App已登录，重新获取参数');
                        verify();
                    }
                })
                .catch(err => {
                    console.log("error");
                    console.log(err);
                    Toast.fail('请求错误');
                })
        } else {
            verify();
        }
    }
    function verify() {
        // console.log('执行其他');
        let serInfo = localStorage.getItem("userinfo");
        let token = localStorage.getItem("token");
        let groupId = localStorage.getItem("groupId")
        let subjectId = localStorage.getItem("subjectId")
        if (to.meta.requireauth) { // 判断该路由是否需要登录权限
            if (token) { // 通过vuex state获取当前的token是否存在
                if(((!groupId && !subjectId) || (groupId == 'null' && subjectId == 'null')) && to.meta.selectCate) {
                    // 从哪里来的路由
                    Vue.prototype.$toast("请先选择项目")
                    setTimeout(() => {
                        next({
                            path: '/index/project',
                            // query: {redirect: ('Index') } // 将跳转的路由path作为参数，登录成功后跳转到该路由
                            query: {redirect: (to.name ? to.name : 'Index') } // 将跳转的路由path作为参数，登录成功后跳转到该路由
                        })
                    }, 1000);
                }
                next();
            } else {
                Vue.prototype.$toast("未登录，请先登录")
                // console.log(to.fullPath);
                setTimeout(() => {
                    next({
                        path: '/User',
                        query: {redirect: to.name,isShowModal:true} // 将跳转的路由path作为参数，登录成功后跳转到该路由
                    })
                }, 1000);

            }
        } else {
            //验证 未登录 接收参数
            if(!token) {
                if(typeof(to.params.branch) != "undefined") {
                    localStorage.setItem("branchId",to.params.branch)
                }
            }
            // console.log(groupId);
            // console.log(subjectId);
            // console.log(from.name);
            if(((!groupId && !subjectId) || (groupId == 'null' && subjectId == 'null')) && to.meta.selectCate) {
                // console.log("准备去哪儿", to);
                // 从哪里来的路由
                // console.log("从哪儿来的", from);
                // console.log("返回链接", from.name);
                Vue.prototype.$toast("请先选择项目")
                setTimeout(() => {
                    next({
                        path: '/index/project',
                        // query: {redirect: ('Index') } // 将跳转的路由path作为参数，登录成功后跳转到该路由
                        query: {redirect: (to.name ? to.name : 'Index') } // 将跳转的路由path作为参数，登录成功后跳转到该路由
                    })
                }, 1000);
            }
            // localStorage.setItem("branchId",)
            // console.log();
            next();
        }
    }
})


export default router
